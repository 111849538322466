import dayjs from "dayjs";
import { services } from "@/utils/config/services";
import { customFetch } from "../customFetch";
import { Sorter } from "../sorter";
import { filterDateFormat } from "../constants";

export interface IncidentsFilters {
  aggerVersion: string;
  status: string;
  from: string;
  to: string;
}

function formatEventDateFilter(from: string, to: string) {
  const formattedFrom = dayjs(from, filterDateFormat)
    .set("h", 0)
    .set("m", 0)
    .set("s", 0)
    .set("ms", 0)
    .toISOString();
  const formattedTo = dayjs(to, filterDateFormat)
    .set("h", 23)
    .set("m", 59)
    .set("s", 59)
    .set("ms", 999)
    .toISOString();
  return [formattedFrom, formattedTo];
}

const formatStatusFilter = (value: string | null) => {
  if (value === "Permitted") return true;

  if (value === "Terminated") return false;

  return "All";
};

export const incidents = {
  getIncident: (id: string) => customFetch(`${services.incidents}/${id}`),
  getIncidentsByEndpoint: (
    page: number,
    endpointId: string,
    filters: IncidentsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.incidents}?EndpointId=${endpointId}&PageNumber=${page}&PageSize=20`;
    if (filters.aggerVersion !== "All") {
      url += `&AggerVersion=${filters.aggerVersion}`;
    }
    const statusFilter = formatStatusFilter(filters.status);
    if (statusFilter !== "All") {
      url += `&IsAllowed=${statusFilter}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&DetectedFrom=${from}&DetectedTo=${to}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  getIncidentsBySha256: (page: number, sha256: string) =>
    customFetch(
      `${services.incidents}?sha256=${sha256}&PageNumber=${page}&PageSize=20`,
    ),
  getIncidents: (
    page: number,
    pageSize: number,
    companyId: string,
    filters: IncidentsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.incidents}?CompanyId=${companyId}&PageNumber=${page}&PageSize=${pageSize}`;
    if (filters.aggerVersion !== "All") {
      url += `&AggerVersion=${filters.aggerVersion}`;
    }
    const statusFilter = formatStatusFilter(filters.status);
    if (statusFilter !== "All") {
      url += `&IsAllowed=${statusFilter}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&DetectedFrom=${from}&DetectedTo=${to}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  allowIncident: (id: string) =>
    customFetch(`${services.incidents}/${id}/allow`, { method: "PUT" }),
  blockIncident: (id: string) =>
    customFetch(`${services.incidents}/${id}/block`, { method: "PUT" }),
  restoreIncident: (id: string) =>
    customFetch(`${services.incidents}/${id}/restore`, { method: "POST" }),
  getIncidentsAggerVersions: (companyId: string, filters: IncidentsFilters) => {
    let url = `${services.incidents}/versions?CompanyId=${companyId}`;
    const statusFilter = formatStatusFilter(filters.status);
    if (statusFilter !== "All") {
      url += `&IsAllowed=${statusFilter}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&DetectedFrom=${from}&DetectedTo=${to}`;
    }
    return customFetch(url);
  },
  getIncidentsAggerVersionsByEndpoint: (
    endpointId: string,
    filters: IncidentsFilters,
  ) => {
    let url = `${services.incidents}/versions?EndpointId=${endpointId}`;
    const statusFilter = formatStatusFilter(filters.status);
    if (statusFilter !== "All") {
      url += `&IsAllowed=${statusFilter}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&DetectedFrom=${from}&DetectedTo=${to}`;
    }
    return customFetch(url);
  },
};
