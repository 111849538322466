import { services } from "../config/services";
import { filterDateFormat } from "../constants";
import { customFetch } from "../customFetch";
import { Sorter } from "../sorter";
import dayjs from "dayjs";

export interface EventsFilters {
  type: string;
  location: string;
  from: string;
  to: string;
}

export interface EventsSyncFilters {
  type: string;
  location: string;
  from: string;
  to: string;
}

function formatEventDateFilter(from: string, to: string) {
  const formattedFrom = dayjs(from, filterDateFormat)
    .set("h", 0)
    .set("m", 0)
    .set("s", 0)
    .set("ms", 0)
    .toISOString();
  const formattedTo = dayjs(to, filterDateFormat)
    .set("h", 23)
    .set("m", 59)
    .set("s", 59)
    .set("ms", 999)
    .toISOString();
  return [formattedFrom, formattedTo];
}

export const events = {
  getEvents: (
    page: number,
    pageSize: number,
    companyId: string,
    filters: EventsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.events}?CompanyId=${companyId}&PageNumber=${page}&PageSize=${pageSize}`;
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  getEventsByEndpoint: (
    page: number,
    endpointId: string,
    filters: EventsFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.events}?EndpointId=${endpointId}&PageNumber=${page}&PageSize=20`;
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  getEventsByGroupConfig: (page: number, groupConfigId: string) =>
    customFetch(
      `${services.events}?GroupConfigId=${groupConfigId}&PageNumber=${page}`,
    ),
  getEventsByHash: (page: number, hashId: string) =>
    customFetch(`${services.events}?HashId=${hashId}&PageNumber=${page}`),
  getEvent: (id: string) => customFetch(`${services.events}/${id}`),
  getEventsTypes: (companyId: string, filters: EventsSyncFilters) => {
    let url = `${services.events}/types?CompanyId=${companyId}`;
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
  getEventsLocations: (companyId: string, filters: EventsSyncFilters) => {
    let url = `${services.events}/locations?CompanyId=${companyId}`;
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
  getEventsTypesByEndpoint: (
    endpointId: string,
    filters: EventsSyncFilters,
  ) => {
    let url = `${services.events}/types?EndpointId=${endpointId}`;
    if (filters.location !== "All") {
      url += `&IpCountryCode=${filters.location}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
  getEventsLocationsByEndpoint: (
    endpointId: string,
    filters: EventsSyncFilters,
  ) => {
    let url = `${services.events}/locations?EndpointId=${endpointId}`;
    if (filters.type !== "All") {
      url += `&Type=${filters.type}`;
    }
    if (filters.from && filters.to) {
      const [from, to] = formatEventDateFilter(filters.from, filters.to);
      url += `&CreatedFrom=${from}&CreatedTo=${to}`;
    }
    return customFetch(url);
  },
};
