import { services } from "../config/services";
import { customFetch } from "../customFetch";
import { HashStatus } from "../types/response/hashes";
import { Sorter } from "../sorter";

export interface HashesFilters {
  status: "All" | HashStatus;
}

export interface AddHashRequest {
  status: string;
  processName: string;
  sha256: string;
  companyId: string;
}

export const hashes = {
  getHashes: (
    page: number,
    pageSize: number,
    companyId: string | undefined,
    filters: HashesFilters,
    sorter?: Sorter,
  ) => {
    let url = `${services.hashes}?PageNumber=${page}&PageSize=${pageSize}`;
    if (companyId) url += `&CompanyId=${companyId}`;
    if (filters.status !== "All") {
      url += `&Status=${filters.status}`;
    }
    if (sorter) {
      url += `&OrderBy=${sorter.orderBy}&OrderDirection=${sorter.orderDirection}`;
    }
    return customFetch(url);
  },
  addHash: (payload: AddHashRequest) =>
    customFetch(`${services.hashes}`, {
      method: "POST",
      body: JSON.stringify(payload),
    }),
  changeStatus: (id: string, status: string) =>
    customFetch(`${services.hashes}/${id}`, {
      method: "PUT",
      body: JSON.stringify({ status }),
    }),
  getHash: (id: string) => customFetch(`${services.hashes}/${id}`),
};
