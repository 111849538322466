export const ransomWatch = {
  getPosts: async () => {
    const res = await fetch(
      "https://raw.githubusercontent.com/joshhighet/ransomwatch/main/posts.json",
    );
    return res.json();
  },
  getGroups: async () => {
    const res = await fetch(
      "https://raw.githubusercontent.com/joshhighet/ransomwatch/main/groups.json",
    );
    return res.json();
  },
};
