export const searchParamNames = {
  incidentId: "incident",
  endpointId: "endpoint",
  groupConfigId: "group-config",
  hashId: "hashId",
  eventId: "eventId",
  applicationId: "applicationId",
  ransomWatchGroup: "ransomWatchGroup",
  lift: "lift",
} as const;

export const filterQueryNames = {
  page: "page",
  pageSize: "page-size",
  orderBy: "order-by",
  orderDirection: "order-direction",
  endpointState: "endpoint-state",
  endpointStatus: "endpoint-status",
  endpointLocation: "endpoint-location",
  endpointOperatingSystem: "endpoint-operating-system",
  endpointAggerVersion: "endpoint-agger-version",
  hashStatus: "hash-status",
  eventType: "event-type",
  eventLocation: "event-location",
  eventFrom: "event-from",
  eventTo: "event-to",
  incidentAggerVersion: "incident-agger-version",
  incidentStatus: "incident-status",
  incidentFrom: "incident-from",
  incidentTo: "incident-to",
  ransomWatchGroupName: "ransom-watch-group-name",
} as const;

export const permissions = [
  "create:companies",
  "create:installers",
  "create:licenses",
  "create:roles",
  "create:super_admins",
  "create:role_members",
  "create:users",
  "create:api_clients",
  "delete:installers",
  "delete:roles",
  "delete:role_members",
  "delete:endpoints",
  "delete:role_members",
  "delete:api_clients",
  "delete:users",
  "read:installers",
  "read:licenses",
  "read:users",
  "read:hashes",
  "read:api_clients",
  "read:roles",
  "read:companies",
  "read:endpoints",
  "read:events",
  "read:incidents",
  "read:installers",
  "read:licenses",
  "read:roles",
  "read:users",
  "update:installers",
  "update:licenses",
  "update:roles",
  "update:hashes",
  "update:companies",
  "update:endpoints",
  "update:users",
  "update:api_clients",
  "update:companies",
  "register:endpoints",
  "suspend:companies",
  "send:heartbeats",
  "manage:all",
] as const;

export const orderDirections = {
  ascend: "ASC",
  descend: "DESC",
} as const;

export const pageSizeOptions = [10, 20, 30];

export const defaultPageSize = 10;

export const filterDateFormat = "DD-MM-YYYY";

export const companyIdsWithHiddenDir = ["0112d60e-e8dd-41fb-9abd-5e461e94a61d"];
