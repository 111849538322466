import { services } from "@/utils/config/services";
import { customFetch } from "../customFetch";

interface BaseUser {
  firstName: string;
  lastName: string;
}

interface UpdateUser extends BaseUser {
  suspended: boolean;
}

interface AddUser extends BaseUser {
  companyId: string;
  email: string;
}

export const users = {
  getRoles: () => customFetch(`${services.roles}`),
  getUsers: (page: number, pageSize: number, companyId: string) =>
    customFetch(
      `${services.users}?CompanyId=${companyId}&PageNumber=${page}&PageSize=${pageSize}`,
    ),
  assignRole: async (
    rolesToAssign: string[],
    rolesToDelete: string[],
    userId: string,
  ) => {
    await customFetch(`${services.users}/${userId}/roles`, {
      method: "POST",
      body: JSON.stringify({
        roleIds: rolesToAssign,
      }),
    });
    if (rolesToDelete.length)
      await customFetch(`${services.users}/${userId}/roles`, {
        method: "DELETE",
        body: JSON.stringify({
          roleIds: rolesToDelete,
        }),
      });
  },
  addUser: (payload: AddUser) =>
    customFetch(`${services.users}/create-user`, {
      method: "POST",
      body: JSON.stringify(payload),
    }),
  updateUser: (userId: string, payload: UpdateUser) =>
    customFetch(`${services.users}/${userId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
    }),
  deleteUser: (userId: string) =>
    customFetch(`${services.users}/${userId}`, {
      method: "DELETE",
    }),
};
