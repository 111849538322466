import { Button, Empty, Result, Spin, theme } from "antd";
import React, { PropsWithChildren, ReactNode } from "react";
import styles from "./index.module.scss";

interface Props extends PropsWithChildren {
  isPending: boolean;
  isError: boolean;
  noData: boolean;
  retry: () => void;
  skipChecks?: boolean;
  isPendingComponent?: ReactNode;
  noDataComponent?: ReactNode;
}

function FetchContainer({
  isPending,
  isError,
  skipChecks,
  noData,
  noDataComponent,
  isPendingComponent,
  children,
  retry,
}: Props) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  if (skipChecks) return children;
  if (isPending) {
    if (isPendingComponent) return isPendingComponent;
    return (
      <div
        className={styles.container}
        style={{
          backgroundColor: colorBgContainer,
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <Result
        className={styles.container}
        style={{ backgroundColor: colorBgContainer }}
        status="error"
        title="Oops! An error occurred"
        subTitle="Please try again, and if the issue persists, contact support"
        extra={[
          <Button key="retry" onClick={retry}>
            Retry
          </Button>,
        ]}
      />
    );
  }

  if (noData) {
    if (noDataComponent) return noDataComponent;
    return <Empty className={styles.container} />;
  }

  return children;
}

export default FetchContainer;
