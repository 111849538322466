import { services } from "../config/services";
import { customFetch } from "../customFetch";

export const dashboard = {
  getDailyIncidents: (companyId: string) =>
    customFetch(`${services.stats}/daily-incidents?CompanyId=${companyId}`),
  getVersionUsageStats: (companyId: string) =>
    customFetch(`${services.stats}/version-usage-count?CompanyId=${companyId}`),
  getEndpointsCount: (companyId: string) =>
    customFetch(`${services.stats}/endpoints-count?CompanyId=${companyId}`),
  getIncidentsCount: (companyId: string) =>
    customFetch(`${services.stats}/incidents-count?CompanyId=${companyId}`),
};
