import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { MeResponse } from "../types/response/me";

export function useMe() {
  const query = useQuery<MeResponse | null>({
    queryKey: ["me"],
    queryFn: () => api.auth.getMe(),
    gcTime: 0,
    staleTime: Infinity,
    retryOnMount: false,
    refetchOnMount: false,
  });
  return query;
}
