import { installers } from "./installers";
import { users } from "./users";
import { auth } from "./auth";
import { company } from "./company";
import { dashboard } from "./dashboard";
import { incidents } from "./incidents";
import { events } from "./events";
import { endpoints } from "./endpoints";
import { licenses } from "./licenses";
import { groupConfigs } from "./groupConfigs";
import { hashes } from "./hashes";
import { ransomWatch } from "./ransomWatch";

const api = {
  incidents,
  dashboard,
  installers,
  users,
  auth,
  company,
  endpoints,
  events,
  licenses,
  groupConfigs,
  hashes,
  ransomWatch,
};

export default api;
