import { services } from "@/utils/config/services";
import { customFetch } from "../customFetch";

export const installers = {
  getInstaller: (id: string) => customFetch(`${services.installers}/${id}`),
  getPublicInstallers: (
    page: number,
    pageSize: number,
    activePlatform: string,
  ) =>
    customFetch(
      `${services.installers}?BuildMode=Release&IsProtected=true&IsPublic=true&PageNumber=${page}&PageSize=${pageSize}&Platform=${activePlatform}`,
    ),
};
